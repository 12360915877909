import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { LibType } from '@/consts'
import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import { ListFetchOptions } from '@/core/typing'
import { LibResourceConfig } from '@/typing'
import _ from 'lodash'
import { createLibResource } from './utils'

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'lib_type'
  }
  parseFetchedItems(data): any[] {
    return data.filter(it => {
      return !!LibType.find(l => l.value === it.id)
    })
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL(): string | boolean {
    return `lib_type/${this.params.id}`
  }

  parseFetchedFormData(data: any) {
    return {
      ...data,
      resource: data.resource?.items
        ? this.parseLibResource(data.resource)
        : createLibResource()
    }
  }

  parseLibResource(resource?: LibResourceConfig): LibResourceConfig {
    if (resource?.items) {
      // add new resource type
      //
      if (!_.find(resource.items, { id: 'audio2' })) {
        resource.items.push({
          id: 'audio2',
          name: '听书',
          sort: 1
        })
      }
      return resource
    }
    return createLibResource()
  }

  getRemoveURL(): string {
    return ''
  }

  getSubmitURL(): string {
    return this.isEdit ? `lib_type/${this.params.id}` : 'lib_type'
  }

  async onEditSubmit() {
    if (this.saving) {
      return
    }
    this.saving = true
    try {
      const data = _.pick(this.data, [
        'name',
        'login_type',
        'applications',
        'resource',
        'logo',
        'app_name',
        'app_image'
      ])
      await this.getSubmitMethod()(this.getSubmitURL(), {
        data
      })
      this.visible = false
      this.requestListReload()
      MessageService.open({ message: '保存成功' })
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.saving = false
    }
  }

  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit
  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
