import { LibResourceConfig } from '@/typing'

export const createLibResource = (): LibResourceConfig => {
  const data: LibResourceConfig['items'] = [
    {
      id: 'home',
      name: '首页',
      data: {
        items: []
      }
    },
    {
      id: 'topic',
      name: '书单',
      data: {
        items: []
      }
    },
    {
      id: 'book',
      name: '图书',
      data: {
        items: []
      }
    },
    {
      id: 'magazine',
      name: '期刊',
      data: {
        items: []
      }
    },
    {
      id: 'eduStudent',
      name: '校园阅读学生书屋'
    },
    {
      id: 'eduTeacher',
      name: '校园阅读教师阅读'
    },
    {
      id: 'eduParents',
      name: '校园阅读家长阅读'
    },
    {
      id: 'eduTopic',
      name: '校园阅读主题书单'
    },
    {
      id: 'audio',
      name: '有声书屋'
    },
    {
      id: 'testing',
      name: '分级测评'
    },
    {
      id: 'audio2',
      name: '听书'
    }
  ]
  return {
    version: 1,
    items: data.map(d => ({
      ...d,
      alias: '',
      visible: false,
      sort: 1
    })),
    eduFilter: null
  }
}
